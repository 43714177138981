/* eslint-disable camelcase */
import creditCardType from 'credit-card-type';
import { cardNumber } from '../constants/regex';

export const determineNextBillingDate = (contributionDayOfMonth) => {
  const today = new Date();
  const date = today.getDate();

  let month = today.getMonth() + 1;
  if (contributionDayOfMonth <= date) {
    month += 1;
  }
  today.setMonth(month - 1);
  today.setDate(contributionDayOfMonth);

  return today.toLocaleString('en-US', { month: 'long', day: 'numeric' });
};

export const parseCardType = (value) => {
  const isValidCcFormat = value.match(cardNumber);
  if (isValidCcFormat) {
    return creditCardType(value)[0]?.type || null;
  }
  return null;
};

export const nthNumber = (number) => {
  if (number > 3 && number < 21) return 'th';
  switch (number % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};
